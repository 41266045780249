@keyframes marquee {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}

.Ticker-span {
  animation-iteration-count: infinite;
  animation-name: marquee;
  animation-timing-function: linear;
}

.brand-ticker-item {
  color: white;
  font-size: 22px;
  text-transform: uppercase;
  white-space: nowrap;
}

.brand-ticker-item a,
.brand-ticker-item span {
  margin: 0 15px;
  transition: color 500ms;
}

body:not(.is-touch) .brand-ticker-item a:hover {
  color: #ffee99;
}

@media (min-width: 960px) {
  .brand-ticker-item {
    font-size: 32px;
  }

  .brand-ticker-item a {
    margin: 0 20px;
  }
}

.main-ticker-item {
  display: flex;
}

.main-ticker-html {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 12px;
  letter-spacing: 2px;
  font-family: "IBM Plex Mono", sans-serif;
  font-weight: 500;
  padding-right: 50px;
}

.main-ticker-html a {
  position: relative;
}

.main-ticker-html a::after {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  background-color: #000;
  left: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 200ms;
}

body:not(.is-touch) .main-ticker-html:hover a::after {
  opacity: 1;
}
