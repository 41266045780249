@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&family=IBM+Plex+Sans:ital,wght@0,400;0,500;0,700;1,400&display=swap");

@font-face {
  font-display: block;
  font-family: "IBM Plex Sans", sans-serif;
}

@font-face {
  font-display: block;
  font-family: "IBM Plex Mono", sans-serif;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: IBM Plex Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

body.overlay-open {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul,
ol {
  list-style: none;
  padding: 0;
}

/* A elements that don't have a class get default styles */
a {
  color: inherit;
  text-decoration: none;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
  border: none;
  outline: none;
  background: none;
  color: inherit;
}

button {
  background: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
}

/* Images */

.Image.loading {
  opacity: 0;
}

.Image {
  transition: opacity 500ms;
  opacity: 1;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-duration: 0.01ms !important;
  }
}

/* Focus visible */

html.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

html.js-focus-visible .focus-visible {
  outline: 3px solid #528deb;
}

/* Text */

.bold-span {
  font-weight: 700;
}

.indented-paragraph p {
  display: inline;
}

/* Buy wear return repeat */

@media (max-width: 639px) {
  .buy-wear-return-repeat {
    height: 95%;
    width: 95%;
  }
}
