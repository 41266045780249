.blog-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 23px;
}

@media (min-width: 640px) {
  .blog-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 960px) {
  .blog-list {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2.38vw;
    grid-row-gap: 2.38vw;
  }
}

.blog-item-title {
  text-transform: uppercase;
  color: inherit;
  transition: color 200ms;
  font-size: 22px;
}

.blog-item-title-highlight {
  background-size: 100% 2px;
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-image: linear-gradient(black, black);
  transition: background-image 200ms;
}

.blog-item-title-highlight.hovered {
  background-image: linear-gradient(transparent, transparent);
}
