.swiper-button-prev,
.swiper-button-next {
  height: 30px;
  width: 30px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 200ms;
}

.swiper-button-next {
  transform: rotate(180deg);
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: "";
}

/* Styles for LatestDrop */

.reskinned-swiper {
  overflow: hidden;
  width: 100vw;
}

.reskinned-swiper .swiper {
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: column-reverse;
  overflow: visible;
}

.reskinned-swiper-nav {
  display: none !important;
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -12px;
}

.reskinned-swiper .swiper-button-prev,
.reskinned-swiper .swiper-button-next {
  position: static;
}

.button-inner {
  position: relative;
  height: 100%;
  width: 100%;
}

.button-inner-black,
.button-inner-white {
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.button-inner-black {
  background-color: black;
  transition: opacity 200ms;
}

.button-inner-white {
  background-color: white;
}

body:not(.is-touch) .reskinned-swiper .button-inner-black:hover {
  opacity: 0;
}

.reskinned-swiper .swiper-button-prev {
  margin-right: 1px;
}

@media (min-width: 640px) {
  .reskinned-swiper .swiper {
    margin-left: 4.76vw;
    margin-right: 4.76vw;
  }
}

@media (min-width: 960px) {
  .reskinned-swiper-nav {
    display: flex !important;
  }

  .reskinned-swiper .swiper-button-prev,
  .reskinned-swiper .swiper-button-next {
    width: 50px;
    position: static;
  }
}
