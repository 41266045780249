@keyframes x {
  100% {
    transform: translate3d(calc(100vw - var(--item-size)), 0, 0);
  }
}

@keyframes y {
  100% {
    transform: translate3d(
      0,
      calc(var(--container-height) - var(--item-size)),
      0
    );
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin linear infinite;
}

.spin--20 {
  animation-duration: 20s;
}

.spin--30 {
  animation-duration: 30s;
}
