.rs-button-loading {
  margin-top: -8px;
}
.rs-button-loading,
.rs-button-loading:before,
.rs-button-loading:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}
.rs-button-loading {
  color: #d9dad1;
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.rs-button-loading:before,
.rs-button-loading:after {
  content: "";
  position: absolute;
}
.rs-button-loading:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.rs-button-loading:after {
  left: 3.5em;
}

@keyframes bblFadInOut {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
